.ModelSite_PersonalizedService{
    width: -webkit-fill-available;
}
.ModelSite_PersonalizedService .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_PersonalizedService .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_PersonalizedService .img{
    width: 100%;
}
.ModelSite_PersonalizedService .img_example{
    width: 100%;
}
.ModelSite_PersonalizedService .space_bottom{
    margin-bottom: 0px;
}

/* example of how it looks on the website */
.ModelSite_PersonalizedService .ModelPages_PersonalizedService {
    align-items: center;
    background-size: cover;
    display: flex;
    height: auto;
    justify-content: center;
    min-height: 300px;
    position: relative;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .show_data {
    align-items: center;
    display: flex;
    height: -webkit-fill-available;
    justify-content: flex-end;
    position: absolute;
    width: -webkit-fill-available;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .div_text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: absolute;
    width: 48%;
    padding-right: 40px;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .title {
    color: #fff;
    font-family: Bebas Neue, sans-serif;
    font-size: 32px;
    text-transform: uppercase;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .subtitle {
    color: #fff;
    font-family: Bebas Neue, sans-serif;
    font-size: 26px;
    text-transform: uppercase;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .text {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-align: justify;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .background_image {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.ModelSite_PersonalizedService .ModelPages_PersonalizedService .img_home {
    height: -webkit-fill-available;
    margin-bottom: -8px;
}
/* end */