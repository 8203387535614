.PopUp .status_team{
    width: 600px;
    position: relative;
}

.PopUp .status_team .course_data{
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 6px 10px;
}
.PopUp .status_team .border_module{
    width: 100%;
    background-color: #ffffff;
    color: #061821;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.PopUp .status_team .border_module table{
    border: 1px solid #061821;
    margin-top: 10px;
    padding-bottom: 0;
}
.PopUp .status_team .border_module .course_data .name_course{
    width: 100%;
    text-align: center;
}
.PopUp .status_team .list_classe{
    width: calc(100% - 20px);
    padding: 6px 10px;
    border-top: 1px solid #061821;
}
.PopUp .status_team .list_classe .name_course{
    width: 100%;
    flex-grow: 1;
    text-align: left;
}
.PopUp .status_team .list_classe .div_icons{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PopUp .status_team .td_height{
    position: relative;
}
.PopUp .status_team .td_height select{
    border: 1px solid #e2e8f0;
    background-color: #ffffff;
    color: #061821;
}
.PopUp .status_team .td_toast{
    position: absolute;
    top: 0px;
    background: rgb(58 168 86 / 50%);
    color: #ffffff;
    left: 0;
    padding: 6px 10px;
    opacity: 0;
    
    animation: anim_opacity 0.3s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_opacity {
    0%, 20% {
        opacity: 0;
        left: 0;
    }
    100% {
        opacity: 1;
        left: 10px;
    }
}