.ModelSite_Contents{
    width: -webkit-fill-available;
}
.ModelSite_Contents .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_Contents .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_Contents .img{
    width: 100%;
}
.ModelSite_Contents .img_example{
    width: 100%;
}
.ModelSite_Contents .space_bottom{
    margin-bottom: 0px;
}


/* example of how it looks on the website */
.ModelSite_Contents .ModelPages_ExplanationContents {
    height: auto;
    min-height: 300px;
    padding: 60px 20px;
}
.ModelSite_Contents .ModelPages_ExplanationContents .show_data {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
}
.ModelSite_Contents .ModelPages_ExplanationContents .div_text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    width: 48%;
}
.ModelSite_Contents .ModelPages_ExplanationContents .div_text .div_title {
    line-height: 20px;
    margin-top: 28px;
    text-transform: uppercase;
}
.ModelSite_Contents .ModelPages_ExplanationContents .div_text .title {
    color: #254a5e;
    font-family: Bebas Neue, sans-serif;
    font-size: 32px;
}
.ModelSite_Contents .ModelPages_ExplanationContents .div_text .text {
    color: #254a5e;
    font-size: 12px;
    line-height: 26px;
    text-align: justify !important;
}
.ModelSite_Contents .ModelPages_ExplanationContents .div_text .subtitle {
    color: #254a5e;
    font-family: Bebas Neue, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
}
.ModelSite_Contents .ModelPages_ExplanationContents .file {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}
.ModelSite_Contents .ModelPages_ExplanationContents .show_file {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ModelSite_Contents .ModelPages_ExplanationContents .show_icons {
    display: flex;
}
.ModelSite_Contents .ModelPages_ExplanationContents .icon_pdf {
    height: 40px;
    width: 40px;
}
.ModelSite_Contents .ModelPages_ExplanationContents .text_pdf {
    color: #ff5200;
    float: left;
    font-family: Bebas Neue;
    font-size: 22px;
    line-height: 22px;
    text-align: left;
}
.ModelSite_Contents .ModelPages_ExplanationContents .list_img {
    height: -webkit-fill-available;
    position: relative;
    width: 48%;
}
.ModelSite_Contents .ModelPages_ExplanationContents .img {
    position: absolute;
    -webkit-transform: translate(-20%, -50%);
    transform: translate(-20%, -50%);
    width: 160%;
}
/* end */