.ModelSite_Video{
    width: -webkit-fill-available;
}
.ModelSite_Video .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_Video .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_Video .icons_video{
    width: 32px;
    height: auto;
    position: relative;
    top: 4px;
}
.ModelSite_Video .file_bg{
    padding-top: 0px;
}
.ModelSite_Video .input_color{
    width: 160px;
}
.ModelSite_Video .input_code{
    width: 120px;
}
.ModelSite_Video .input_button{
    width: 140px;
}
.ModelSite_Video .space_bottom{
    margin-bottom: 0px;
}
.ModelSite_Video .select_button{
    width: 150px;
}
.ModelSite_Video .name_button{
    width: 120px;
}

/* example of how it looks on the website */
.ModelSite_Video .div_img{
    width: 100%;
    min-height: 380px;

    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 0 auto;
}
.ModelSite_Video .show_video {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: 48%;
}
.ModelSite_Video .div_video {
    aspect-ratio: 1.777;
    height: auto;
    width: 100%;
    margin-left: 30px;
}
.ModelSite_Video .div_text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
    padding: 38px 38px 38px 0px;
}
.ModelSite_Video .div_text .div_title {
    line-height: 30px;
    text-transform: uppercase;
}
.ModelSite_Video .div_text .subtitle {
    color: #254a5e;
    font-family: Bebas Neue, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}
.ModelSite_Video .div_text .title {
    color: #254a5e;
    font-family: Bebas Neue, sans-serif;
    font-size: 32px;
}
.ModelSite_Video .div_text .text {
    color: #6b6b6b;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-align: justify !important;
}
.ModelSite_Video .button_text {
    background-color: #7c9f06;
    color: #fff;
    cursor: pointer;
    padding: 6px 10px;
    text-align: center;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    width: 130px;
}
.ModelSite_Video .button_text:hover{
    transform: scale(0.96);
}
/* end */