.PopUp .popup_historic{
    width: 900px;
}
.PopUp .popup_historic .reactivate{
    color: #ffffff;
    padding: 6px 14px;
    border-radius: 6px;
    background-color: #7c9f06;
    cursor: pointer;
}

/* table */
.PopUp .popup_historic table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.PopUp .popup_historic table th, .PopUp .popup_historic table td{
    color: #6c757d;
}
/* end */