.ModelSite_StudentWorks{
    width: -webkit-fill-available;
}
.ModelSite_StudentWorks .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_StudentWorks .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_StudentWorks .icons_video {
    width: 32px;
    height: auto;
    position: relative;
    top: 4px;
}
.ModelSite_StudentWorks .file_bg{
    padding-top: 0px;
}
.ModelSite_StudentWorks .img{
    width: 100%;
}
.ModelSite_StudentWorks .img_example{
    width: 100%;
}
.ModelSite_StudentWorks .input_color{
    width: 160px;
}
.ModelSite_StudentWorks .input_code{
    width: 120px;
}

/* example of how it looks on the website */
.ModelSite_StudentWorks .ModelPages_StudentWorks {
    background-size: cover;
    height: auto;
    min-height: 400px;
    position: relative;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .show_data {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 30px;

    position: absolute;
    width: -webkit-fill-available;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .show_video {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 60%;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .show_video_space_right {
    padding-right: 20px;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .title {
    color: #254a5e;
    font-family: Bebas Neue;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .div_video {
    aspect-ratio: 1.777;
    height: auto;
    width: 100%;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .background_image {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.ModelSite_StudentWorks .ModelPages_StudentWorks .img_home {
    margin-bottom: -8px;
    height: 100%;
    max-height: 400px;
}
/* end */