.Login{
    width: 100%;
    height: 100%;
    background-image: url('/public/assets/bg_login.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Login .div_login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #ffffff;
}
.Login .logotipo{
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Login .img_logo{
    width: auto;
    height: auto;
}
.Login .show_login{
    width: 300px;
    height: auto;
    padding: 20px;
    background-color: #061821;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.Login .div_input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.Login .space_input{
    flex-grow: 1;
    position: relative;
    width: -webkit-fill-available;
}
.Login .space_input input{
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #e8f0fe;
    color: #6c757d;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    width: -webkit-fill-available;
    padding: 14px 10px;
}
.Login .space_input ::placeholder{
    color: #7fb3cf;
    font-family: 'Roboto Condensed', sans-serif;
}
.Login .div_button{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.Login .button_login{
    color: #e0e3e4;
    background-color: #37a7e5;
    width: -webkit-fill-available;
    padding: 14px 10px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    border: none;
}
.Login .msg_error {
    width: auto;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FC4F81;
    background: linear-gradient(0deg, rgba(252, 79, 129, 0.2), rgba(252, 79, 129, 0.2)), #1C2023;
}
.Login .msg_none{
    display: none;
}
.Login .register{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
    border: transparent;
}
::placeholder {
    color: #6c757d;
    font-size: 16px;
}
.Login .width_logo {
    display: block;
    height: auto;
    width: 200px;
}
.Login .svg_logotipo {
    fill: #fff;
}