.PopUp .NameMenuSite{
    width: min-content;
}
.PopUp .NameMenuSite .div_login{
    padding-top: 16px;
}
.PopUp .NameMenuSite .div_login table th{
    color: #061821;
    background-color: #ffffff;
}
.PopUp .NameMenuSite .div_login table td{
    background-color: #061821;
}