.FinancialExit_details {
    padding: 20px;
    padding-bottom: 70px;
}
.FinancialExit_details .data_select{
    width: 140px;
}
.FinancialExit_details .pass{
    width: 120px;
}
.FinancialExit_details .input_date{
    width: 150px;
}
.FinancialExit_details .input_cash, .FinancialExit_details .input_fee, .FinancialExit_details .input_agency{
    width: 80px;
    text-align: center;
}
.FinancialExit_details .select_search{
    width: -webkit-fill-available;
}
.FinancialExit_details .select_bank, .FinancialExit_details .select_payment, .FinancialExit_details .input_data{
    width: 160px;
}