.PopUp .confirmation {
    width: 400px;
}
.PopUp .confirmation .title_confirmation{
    justify-content: center;
}
.PopUp .confirmation .div_confirmation{
    flex-direction: row;
}
.PopUp .confirmation .opt_select {
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    width: 100px;
    text-align: center;
}
.PopUp .confirmation .div_data{
    flex-direction: column;
}
.PopUp .confirmation .yes_update {
    background-color: #76c57e;
}
.PopUp .confirmation .yes_update:hover {
    background-color: #43AE88;
}
.PopUp .confirmation .not_update {
    background-color: #e95656;
}.PopUp .confirmation .not_update:hover {
    background-color: #CC4D7B;
}

.PopUp .confirmation .alert{
    color: #d6f000;
    margin-bottom: 20px;
}
.PopUp .confirmation .alert .attention{
    font-size: 18px;
    font-weight: 600;
}