.ModelSite_Depositions{
    width: -webkit-fill-available;
}
.ModelSite_Depositions .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_Depositions .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_Depositions .list_profiles{
    border: 1px solid #ffffff;
    padding: 16px;
}
.ModelSite_Depositions .img{
    width: 100%;
}
.ModelSite_Depositions .img_example{
    width: 100%;
}
.ModelSite_Depositions .space_bottom{
    margin-bottom: 0px;
}
.ModelSite_Depositions .input_name{
    width: 120px;
}
.ModelSite_Depositions .input_date{
    width: 100px;
}
.ModelSite_Depositions .data_{
    width: 100px;
}
.ModelSite_Depositions .show_profile{
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 30px;
    width: -webkit-fill-available;
}
.ModelSite_Depositions .show_profile:nth-last-child(-n + 1) {
    margin-bottom: 0px;
}
.ModelSite_Depositions .div_show_img .icons_profile{
    width: 40px;
    height: 40px;
}

/* example of how it looks on the website */
/* end */