.Page_Students_Details {
    padding: 20px;
    padding-bottom: 70px;
}
.Page_Students_Details .data_select, .Page_Students_Details .access{
    width: 140px;
}
.Page_Students_Details .pass, .Page_Students_Details .login{
    width: 120px;
}
.Page_Students_Details .cpf{
    width: 86px;
    text-align: center;
}
.Page_Students_Details .email, .Page_Students_Details .city{
    width: 200px;
}
.Page_Students_Details .input_name_video{
    width: 260px;
}
.Page_Students_Details .phone, .Page_Students_Details .country{
    width: 140px;
}
.Page_Students_Details .uf{
    width: 26px;
    text-align: center;
}
.Page_Students_Details .cep{
    width: 76px;
    text-align: center;
}
.Page_Students_Details .page_released{
    width: 254px;
    padding: 2px;
    height: 42px;
}
.Page_Students_Details .name_team{
    width: -webkit-fill-available;
}
.Page_Students_Details .courses{
    padding-top: 0px;
}
.Page_Students_Details .add_new_course {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    width: -webkit-fill-available;
}
.Page_Students_Details .select_list_data{
    min-width: 200px;
}
.Page_Students_Details .show_courses{
    width: -webkit-fill-available;
    /* padding: 10px;
    background-color: #ffffff; */
}
.Page_Students_Details .show_courses table th{
    border-right: 1px solid #061821;
    border-bottom: 1px solid #061821;
}
.Page_Students_Details .show_courses table td{
    border-right: 1px solid #061821;
    border-bottom: 1px solid #061821;
}
.Page_Students_Details .table_hover:hover{
    background-color: #ffffff;
}
.Page_Students_Details .table_border_left{
    border-left: 1px solid #C5D2E8;
}

/* table */
.Page_Students_Details table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Page_Students_Details table th, .Page_Students_Details table td{
    color: #6c757d;
    border-bottom: 1px solid #E2E8F0;
}
/* end */

.Page_Students_Details .input_code {
    width: 106px;
    text-align: center
}
.Page_Students_Details .select_course{
    min-width: 300px;
}

.Page_Students_Details .div_recording{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Students_Details .div_recording:nth-child(n+2){
    border-top: 1px solid #08374f;
    padding-top: 16px;
    margin-top: 16px;
}