.Finacial_Balance{
    width: -webkit-fill-available;
    height: auto;
    padding: 20px;
    padding-bottom: 70px;
}
.Finacial_Balance .list_month{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: -webkit-fill-available;
    overflow-x: auto;
    padding-bottom: 16px;
}
.Finacial_Balance .div_month{
    font-family: 'inter';
    background-color: #009BA2;
    color: #ffffff;
    height: 30px;
    min-height: 30px;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
}
.Finacial_Balance .div_month:hover, .Finacial_Balance .month_active{
    background-color: #339542;
}
.Finacial_Balance .icon_next{
    transform: rotate(180deg);
}
.Finacial_Balance .current_year{
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.Finacial_Balance .show_text_return_year{
    cursor: pointer;
    height: 30px;
    padding: 0px 16px;
    background-color: #324d6b;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Finacial_Balance .show_month_inf{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: -webkit-fill-available;
}
.Finacial_Balance .show_cash{
    background-color: #324d6b;
    color: #ffffff;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-width: 70px;
}
.Finacial_Balance .title_grafic{
    text-align: center;
    margin-bottom: 10px;
}
.Finacial_Balance .div_grafic{
    width: -webkit-fill-available;
    background-color: #ffffff;
    padding: 10px;
}
.Finacial_Balance .show_grafic{
    width: -webkit-fill-available !important;
    height: 260px !important;
}
svg{
    width: auto;
    height: auto;
}
.Finacial_Balance .legend_grafic{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}
.Finacial_Balance .legend_name{
    padding: 4px 10px;
    width: 100px;
    color: #ffffff;
    cursor: default;
}
.Finacial_Balance .details{
    cursor: pointer;
}
.Finacial_Balance .icon_{
    width: 30px;
    height: 30px;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
    display: block;
}

.Finacial_Balance .div_download{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: -webkit-fill-available;
    padding-bottom: 16px;
    margin-top: 20px;
}
.Finacial_Balance .download{
    background-color: #324d6b;
    color: #ffffff;
    padding: 6px 16px;
}

.Finacial_Balance .apexcharts-menu{
    min-width: 132px;
}