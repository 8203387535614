.FinancialEntry_details {
    padding: 20px;
    padding-bottom: 70px;
}
.FinancialEntry_details .data_select{
    width: 140px;
}
.FinancialEntry_details .pass{
    width: 120px;
}
.FinancialEntry_details .input_date{
    width: 150px;
}
.FinancialEntry_details .input_cash, .FinancialEntry_details .input_fee, .FinancialEntry_details .input_agency{
    width: 80px;
    text-align: center;
}
.FinancialEntry_details .select_search{
    width: -webkit-fill-available;
}
.FinancialEntry_details .select_bank, .FinancialEntry_details .select_payment, .FinancialEntry_details .input_data{
    width: 160px;
}