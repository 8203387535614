.Site_Questionnaire_details {
    padding: 20px;
    padding-bottom: 70px;
}
.Site_Questionnaire_details .data_select{
    width: 140px;
}
.Site_Questionnaire_details .pass{
    width: 120px;
}
.Site_Questionnaire_details .input_date{
    width: 150px;
}
.Site_Questionnaire_details .input_cash, .Site_Questionnaire_details .input_fee, .Site_Questionnaire_details .input_agency{
    width: 80px;
    text-align: center;
}
.Site_Questionnaire_details .select_search{
    width: -webkit-fill-available;
}
.Site_Questionnaire_details .select_bank, .Site_Questionnaire_details .select_payment, .Site_Questionnaire_details .input_data{
    width: 160px;
}
.Site_Questionnaire_details .trash {
    width: 24px;
    height: 24px;
    display: block;
}
.Site_Questionnaire_details .div_show_data_specific{
    width: calc(100% - 20px);
}