.Indicators {
    padding: 20px;
}
.Indicators .list_current_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
}
.Indicators .list_current_data .show_qtd{
    width: auto;
    flex-grow: 1;
    min-width: 264px;
    height: 110px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.Indicators .list_current_data .show_qtd:hover{
    transform: scale(0.98);
}
.Indicators .list_current_data .show_qtd .number{
    font-size: 40px;
    font-weight: 700;
    color: #2D3C53;
    border: 1px solid #2D3C53;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.Indicators .list_current_data .show_qtd .title{
    font-size: 14px;
    padding: 10px;
    color: #ffffff;
    background-color: #2D3C53;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.Indicators .new_block_grafic{
    /* margin-top: 40px; */
}
.Indicators .new_block_grafic .icon_next{
    transform: rotate(180deg);
}
.Indicators .list_graffic{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 40px;
}
.Indicators .list_graffic .list_data_page{
    width: 50%;
}
.Indicators .list_data_page{
    flex-grow: 1;
}
.Indicators .list_data_page .div_grafic{
    width: -webkit-fill-available;
    background-color: #ffffff;
    padding: 10px;
}
.Indicators .list_data_page .div_grafic_{
    flex-grow: 1;
    background-color: #ffffff;
    padding: 10px;
}

.Indicators .div_show_table{
    width: calc(100% - 20px);
    background-color: #ffffff;
    padding: 10px;
}
.Indicators .div_show_table .div_title{
    font-family: 'bebas neue';
    font-size: 26px;
    font-weight: 500;
    color: #6c757d;
    line-height: normal;
    text-align: center;
    border-bottom: 1px solid #E2E8F0;
    margin-bottom: 10px;
}
.Indicators .div_show_table .list_table{
    
}
.Indicators .div_show_table .list_table table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Indicators .div_show_table .list_table table th, .Indicators .div_show_table .list_table table td{
    color: #6c757d;
    padding: 6px 10px;
}
.Indicators .div_show_table .list_table table td:nth-child(n + 1){
    border-bottom: 1px solid #E2E8F0;
}
.Indicators .div_show_table .list_table table tr:nth-child(2n){
    background: #ffffff;
}