.ModelSite_Home{
    width: -webkit-fill-available;
}
.ModelSite_Home .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_Home .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_Home .img{
    width: 100%;
    position: relative;
}
.ModelSite_Home .img_example{
    width: 100%;
}

/* example of how it looks on the website */
.ModelSite_Home .show_example_data{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: -webkit-fill-available;
    justify-content: center;
    position: absolute;
    width: -webkit-fill-available;
    z-index: 2;
}
.ModelSite_Home .show_example_data .title_1{
    color: #fff;
    font-family: Bebas Neue, sans-serif;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    text-shadow: 0 0 15px #000;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.ModelSite_Home .div_highlight {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    text-align: center;
}
.ModelSite_Home .text {
    color: #494b4f;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.ModelSite_Home .highlight {
    color: #fff;
    font-family: Bebas Neue,sans-serif;
    font-size: 32px;
    line-height: 28px;
    text-shadow: 0 0 15px #000;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.ModelSite_Home .button, .ModelSite_Home .list_text {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 16px;
}
.ModelSite_Home .button {
    flex-direction: row;
    gap: 12px;
    text-transform: uppercase;
}
.ModelSite_Home .button_text {
    background-color: #7c9f06;
    color: #fff;
    cursor: pointer;
    padding: 6px 10px;
    text-align: center;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    width: 130px;
}
.ModelSite_Home .button_text:hover{
    transform: scale(0.96);
}
/* end */


.ModelSite_Home .file_bg{
    padding-top: 0px;
}
.ModelSite_Home .select_button{
    width: 150px;
}
.ModelSite_Home .name_button{
    width: 120px;
}