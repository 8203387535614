.PopUp .show_file {
    width: 760px;
}
.PopUp .show_file .title_file{
    justify-content: right;
}
.PopUp .show_file .show_img{
    max-width: 90%;
    max-height: 400px;
}
.PopUp .show_file .aspect_video{
    aspect-ratio: 16/9;
    width: -webkit-fill-available;
}
.PopUp .show_file .div_video{
    width: -webkit-fill-available;
    height: 400px;
}
