.Page_Historic {
    padding: 20px;
    padding-bottom: 70px;
}

.Page_Historic .show_img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Page_Historic .show_img .img_examplo{
    width: auto;
    height: 32px;
    display: flex;
}