.Site_PaymentTypes{
    padding: 20px;
    padding-bottom: 70px;
}
.Site_PaymentTypes .highlight {
    border-color: #111827;
    border: 1px solid #111827;
    padding: 5px 10px;
    cursor: pointer;
}
.Site_PaymentTypes .high_active {
    background-color: #111827;
    color: #ffffff;
}
.Site_PaymentTypes .show_payment{
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 30px;
    width: -webkit-fill-available;
}
.Site_PaymentTypes .select_type{
    width: 120px;
}
.Site_PaymentTypes .select_type_table{
    width: 74px;
    background-color: #ffffff;
    border: 2px solid #061821;
    color: #061821;
}
.Site_PaymentTypes .select_category{
    background-color: #ffffff;
    border: 2px solid #061821;
    color: #061821;
}
.Site_PaymentTypes .input_cash{
    width: 64px;
    text-align: center;
}
.Site_PaymentTypes .select_cost_benefit{
    width: 200px;
}
.Site_PaymentTypes .input_color{
    width: 80px;
    text-align: center;
}
.Site_PaymentTypes .div_color{
    width: 24px;
    height: 34px;
    border: 1px solid #111827;
}
.Site_PaymentTypes .trash{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Site_PaymentTypes .div_category{
    padding-top: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid #6c757d;
}
.Site_PaymentTypes .div_category:nth-last-child(-n + 1) {
    border: none;
}
.Site_PaymentTypes .space_bottom{
    margin-bottom: 0;
}


.Site_PaymentTypes .div_high{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}
.Site_PaymentTypes .div_high .high{
    width: 80px;
    text-align: center;
    padding: 6px 0px;
    border: 2px solid #061821;
    cursor: pointer;
}
.Site_PaymentTypes .div_high .high:hover{
    color: #ffffff;
    border-color: #05496c;
    background-color: #05496c;
}
.Site_PaymentTypes .div_high .high_active{
    color: #ffffff;
    border-color: #061821;
    background-color: #061821;
}


/* table */
.Site_PaymentTypes table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Site_PaymentTypes table th, .Site_PaymentTypes table td{
    color: #6c757d;
}
/* end */