.Page_AccessDash_Details {
    padding: 20px;
    padding-bottom: 70px;
}
.Page_AccessDash_Details .data_select{
    width: 140px;
}
.Page_AccessDash_Details .pass{
    width: 120px;
}
.Page_AccessDash_Details .list_sub_menu{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}