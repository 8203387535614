
.PopUp .data_profile .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: -webkit-fill-available;
}
.PopUp .data_profile .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.PopUp .data_profile .div_input {
    margin: 0;
}
.PopUp .data_profile .show_div_input{
    width: auto;
}


.PopUp .data_profile .form_profile {
    width: -webkit-fill-available;
}
.PopUp .data_profile .show_profile_popup{
    margin-top: 0;
    margin-bottom: 16px;
}
.PopUp .data_profile .img_popup{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    gap: 6px;
}
.PopUp .data_profile .position_popup{
    justify-content: flex-start !important;
}
.PopUp .data_profile .input_name{
    width: 120px;
}
.PopUp .data_profile .input_date{
    width: 100px;
}