.Page_Historic {
    padding: 20px;
    padding-bottom: 70px;
}
/* table */
.Page_Historic table{
    padding-bottom: 0;
}
.Page_Historic table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Page_Historic table th, .Page_Historic table td{
    color: #6c757d;
}
/* end */

.Page_Historic .list_questions{
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #061821;
}
.Page_Historic .list_questions:nth-last-child(-n + 1){
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.Page_Historic .list_questions .title{
    font-size: 14px;
    font-weight: bold;
}
.Page_Historic .list_questions .text{

}
.Page_Historic .list_questions .response{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}
.Page_Historic .list_questions .response .show_opt{
}
.Page_Historic .list_questions .response .show_opt .selected{
    width: 16px;
    height: 16px;
}

.Page_Historic .div_opt_alt_respose{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}
.Page_Historic .div_opt_alt_respose div {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}