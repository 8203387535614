.Page_Courses_Details_Classes{
    padding: 20px;
    padding-bottom: 70px;
}
.Page_Courses_Details_Classes .data_select, .Page_Courses_Details_Classes .select_video{
    width: 140px;
}
.Page_Courses_Details_Classes .select_type {
    width: 220px;
}
.Page_Courses_Details_Classes .input_code{
    width: 106px;
    text-align: center
}
.Page_Courses_Details_Classes .free{
    width: 146px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-color: #061821;
    border: 1px solid #061821;
    cursor: pointer;
    transition: 0.5s;
}
.Page_Courses_Details_Classes .free_active{
    background-color: #111827;
    color: #ffffff;
}
.Page_Courses_Details_Classes .data_select_module{
    width: 200px;
}
.Page_Courses_Details_Classes .others{
    font-weight: 700;
    font-size: 20px;
}
.Page_Courses_Details_Classes .select_module{
    width: 360px;
    min-width: 120px;
}

.Page_Courses_Details_Classes .div_download:nth-child(n+3){
    border-top: 1px solid #08374f;
    padding-top: 16px;
    margin-top: 16px;
}

.Page_Courses_Details_Classes .show_img_ .img_example{
    width: 42px;
    height: 42px;
}