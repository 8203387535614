.PopUp .details_course{
    width: 600px;
}

.PopUp .details_course .course_data{
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 6px 10px;
}
.PopUp .details_course .border_module{
    width: 100%;
    background-color: #ffffff;
    color: #061821;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.PopUp .details_course .border_module .course_data .name_course{
    width: 100%;
    text-align: center;
}
.PopUp .details_course .list_classe{
    flex-direction: row;
    width: calc(100% - 20px);
    padding: 6px 10px;
    border-top: 1px solid #061821;
}
.PopUp .details_course .list_classe .name_course{
    flex-grow: 1;
    text-align: left;
}
.PopUp .details_course .list_classe .div_icons{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}