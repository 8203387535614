.PopUp .form_doubts{
    width: -webkit-fill-available;
}
.PopUp .div_doubts{
    margin-bottom: 30px;
    width: -webkit-fill-available;
}
.PopUp .div_doubts .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}