
.PopUp .Questions{
    width: 900px;
    margin: 4% auto;
}
.PopUp .Questions .list_inf_{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.PopUp .Questions .list_inf_questions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff;
}
.PopUp .Questions .list_inf_questions:nth-last-child(-n + 1){
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
.PopUp .Questions .list_inf_questions .conclusion_title{
    font-family: 'Bebas Neue';
    font-size: 32px;
    line-height: 30px;
    color: #ffffff;
}
.PopUp .Questions .list_inf_questions .list_opt_click{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
}
.PopUp .Questions .list_inf_questions .list_opt_click .div_opt{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    font-size: 12px;
}
.PopUp .Questions .list_inf_questions .list_opt_click .opt_selected{
    color: #3aa856;
    font-size: 14px;
}
.PopUp .Questions .list_inf_questions .list_opt_click .div_opt .div_opt_number{
    font-size: 12px;
}
.PopUp .Questions .list_inf_questions .list_opt_click .div_opt .div_opt_input .input_radio{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.PopUp .Questions .list_inf_questions .list_opt_click .div_opt .div_opt_title{
    font-size: 12px;
    text-align: center;
}
.PopUp .Questions .list_inf_questions .list_opt_click .div_opt .div_opt_subtitle{
    font-size: 10px;
    color: #758597;
    text-align: center;
}