.Page_Courses {
    padding: 20px;
}
.Page_Courses .copy_none{
    display: none;
}
.Page_Courses .copy_link{
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    
    animation: show_notification 0.3s linear;
    animation-fill-mode: forwards;
}
@keyframes show_notification {
    0%{
        bottom: 0px;
    }
    100%{
        bottom: 10px;
    }
}
.Page_Courses .copy_hide {
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);

    animation: hide_notification 0.4s linear;
    animation-fill-mode: forwards;
}
@keyframes hide_notification {
    0%{
        bottom: 10px;
    }
    98%{
        bottom: 0px;
    }
    100%{
        display: none;
    }
}
.Page_Courses .copy_link .div_text{
    padding: 10px 20px;
    background-color: #3aa856;
    color: #ffffff;
    width: max-content;
    opacity: 0.7;
}

.Page_Courses .show_img{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Page_Courses .show_img .img_examplo{
    width: auto;
    height: 32px;
    display: flex;
}