.popup_return_response{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .return_response{
    width: 360px;
    top: -6%;
}
.PopUp .return_response .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .return_response .title_return{
    font-size: 16px;
    font-family: 'Inter';
}
.PopUp .return_response .close_return{
    background-color: #43a047;
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 20px;
}
