.ModelSite_ModulesWorkload {
    width: -webkit-fill-available;
}
.ModelSite_ModulesWorkload .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_ModulesWorkload .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_ModulesWorkload .block_cinza .block_title{
    margin-bottom: 0px;
}
.ModelSite_ModulesWorkload .input_color{
    width: 120px;
    text-align: center;
}
.ModelSite_ModulesWorkload .input_hour{
    width: 40px;
    text-align: center;
}
.ModelSite_ModulesWorkload .td_{
    padding: 6px 8px;
}
.ModelSite_ModulesWorkload .show_div_color{
    display: flex;
    gap: 6px;
}
.ModelSite_ModulesWorkload .img{
    width: 100%;
}
.ModelSite_ModulesWorkload .img_example{
    width: 100%;
}
.ModelSite_ModulesWorkload .input_new_bg{
    background-color: #ffffff;
    border: 2px solid #061821;
    color: #061821;
}

/* table */
.ModelSite_ModulesWorkload table th{
    background-color: transparent !important;
    border-bottom: 1px solid #E2E8F0 !important;
    background-color: #ffffff;
}
.ModelSite_ModulesWorkload table th, .ModelSite_ModulesWorkload table td{
    color: #6c757d;
    background-color: #ffffff;
}
/* end */

/* example of how it looks on the website */
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload {
    background-color: #071b2f;
    height: auto;
    margin: 0 auto;
    min-height: 300px;
    padding-top: 60px;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .show_data {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin: 0 auto;
    position: relative;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .bg_img {
    height: -webkit-fill-available;
    width: 46%;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .img {
    bottom: 0;
    height: auto;
    position: absolute;
    width: 50%;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .div_text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-family: Bebas Neue, sans-serif;
    gap: 40px;
    justify-content: center;
    padding-bottom: 60px;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .title {
    color: #ffad00;
    font-size: 32px;
    line-height: 32px;
    padding-left: 5px;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .table {
    border-spacing: 0;
    color: #ffffffb3;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload table {
    width: 100%;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload table tr{
    background-color: #fff
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload table tr td{
    font-size: 12px;
    padding: 2px 10px;
    border: transparent !important;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .table_numb {
    color: #0a2541;
    height: 30px;
    text-align: center;
    width: auto;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .table_text {
    background-color: #103860;
    padding-left: 10px;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .table_time {
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    width: auto;
}
.ModelSite_ModulesWorkload .ModelPages_ModulesWorkload .time {
    align-items: flex-start;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 12px;
    justify-content: center;
    padding-left: 5px;
}
/* end */