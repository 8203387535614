.PopUp .popup_BalanceDetails{
    width: 900px;
}
.PopUp .popup_BalanceDetails .download {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: -webkit-fill-available;
    background-color: transparent;
    padding: 0px;
}
.PopUp .popup_BalanceDetails .div_download {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: #5a5a5ad6;
}

/* table */
.PopUp .popup_BalanceDetails table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.PopUp .popup_BalanceDetails table th, .PopUp .popup_BalanceDetails table td{
    color: #6c757d;
    border-bottom: 1px solid #E2E8F0;
}
/* end */