.Page_Team_Details {
    padding: 20px;
    padding-bottom: 70px;
}
.Page_Team_Details .data_select{
    width: 140px;
}
.Page_Team_Details .pass{
    width: 120px;
}
.Page_Team_Details .show_name_study{
    width: 200px;
}
.Page_Team_Details .show_data_team{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Page_Team_Details .show_data_team table{
    padding-bottom: 0;
}
.Page_Team_Details .show_data_team table .table_height{
    height: 40px;
    padding: 0px 12px;
    position: relative;
}
.Page_Team_Details .show_data_team table .table_end_height:nth-last-child(-n+1){
    height: 52px;
}

.Page_Team_Details .show_data_team table .table_td{
    border-bottom: 1px solid #E2E8F0;
    border-right: 1px solid #E2E8F0;
}
.Page_Team_Details .show_data_team table .table_td:nth-last-child(-n+1){
    border-right: 0;
}
.Page_Team_Details .show_data_team table .table_td .div_opt_alt{
    justify-content: center;
}
.Page_Team_Details .show_data_team table .table_left{
    border-left: 1px solid #E2E8F0;
}
.Page_Team_Details .show_data_team table .table_right{
    border-right: 1px solid #E2E8F0;
}
.Page_Team_Details .show_data_team table .table_name_course{
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;
}
.Page_Team_Details .show_data_team table .table_name_course:nth-last-child(-n+1){
    border-left: 0;
}

.Page_Team_Details .show_data_team .div_name_study{
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    border-right: 1px solid #E2E8F0;
    overflow: hidden;
}
.Page_Team_Details .show_data_team table .show_name{
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.Page_Team_Details .show_data_team table .show_name .show_icon_cancel{
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(45deg, transparent 0%, transparent 50%, #f00000 51%, #f00000 100%);
    width: 10px;
    height: 10px;
}
.Page_Team_Details .show_data_team .div_list_classes{
    flex-grow: 1;
    overflow-x: auto;
}
.Page_Team_Details .show_data_team .div_list_classes .show_classes_th{
    padding: 0;
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    cursor: pointer;
}
.Page_Team_Details .show_data_team .div_list_classes .show_classes_td{
    text-align: center;
    border-right: 1px solid #061821;
    position: relative;
}
.Page_Team_Details .show_data_team .div_list_classes .show_classes_td:nth-last-child(-n+1){
    border-right: none;
}
.Page_Team_Details .show_data_team .div_list_classes .list_opt_status{
}
.Page_Team_Details .show_data_team .div_list_classes .data_classes{
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    cursor: pointer;
}

/* table */
.Page_Team_Details table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Page_Team_Details table th, .Page_Team_Details table td{
    color: #6c757d;
    border-bottom: 1px solid #E2E8F0;
}
/* end */